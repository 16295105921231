import React from "react"
import "_data/normalize.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/500.css"
import { Content, Screen, HeaderContainer } from "_data/global_styles"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import Button from "_components/buttons/button_parallax"
import Footer from "_components//nav/footer/_footer"
import Header from "_components/nav/header/_header"
import PageHeadline from "_components/misc/pageHeadline"
import Seo from "_components/Seo/faq"
import styled from "styled-components"
import {
  color_primButton,
  color_subHeading,
  color_paragraphHighlite,
} from "_src/assets/data/global_colors"

const FAQ = (props) => {
  return (
    <>
      {!props.skipHeader && (
        <>
          <Seo />
          <HeaderContainer>
            <Header />
          </HeaderContainer>
        </>
      )}
      <Screen>
        <Content>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.65 }}
          >
            <PageHeadline copy="Frequently Asked Questions." />

            {!props.skipHeader && (
              <header style={{ marginLeft: "1vw", color: "#303c6c" }}>
                <Answer>
                  We've anticipated a few questions that you may have about
                  ActiveEMDR and the Bilateral / Dual Attention Stimulation web
                  tools. If your question isn't answered below, please contact
                  us and we will get back to you.
                </Answer>

                <ButtonWrapper style={{ width: "170px" }}>
                  <Button
                    color={color_primButton}
                    shadowColor="black"
                    copy="Ask a Question"
                    destination="/contact"
                    height={48}
                    width={160}
                    fontScale={0.8}
                  />
                </ButtonWrapper>
              </header>
            )}

            <div style={{ margin: "5vh 0 0 1vw" }}>
              <Question style={{ color: color_subHeading }}>
                When was this FAQ last updated?
              </Question>
              <Answer>It was last updated on March 10th, 2021.</Answer>

              <Question style={{ color: color_subHeading }}>
                What is ActiveEMDR?
              </Question>
              <Answer>
                ActiveEMDR is a free set of tools to help therapists perform
                bilateral stimulation with their remote EMDR clients. It was
                originally created in 2013 as a Windows Application meant to be
                used with in-office clients. However, due to the COVID pandemic
                in early 2020, it was reimagined as a Web App and released to
                the public so that therapists could perform virtual EMDR while
                complying with social distancing recommendations.{" "}
                {!props.skipHeader && (
                  <Link
                    style={{
                      color: color_paragraphHighlite,
                      fontWeight: "800",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    to="/intro/past-present-future/"
                  >
                    Read more here.
                  </Link>
                )}
              </Answer>

              <Question style={{ color: color_subHeading }}>
                Do you have any videos showing how to use these tools?
              </Question>
              <Answer>
                Yes! We have a{" "}
                <a
                  href="https://www.youtube.com/channel/UCcbAmJDgC_GxU0Pombs1iNA"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    color: color_paragraphHighlite,
                    fontWeight: "800",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  YouTube Channel
                </a>{" "}
                that we are slowly filling with content. You can also see the
                latest videos right in the therapist dashboard.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                What happened to all of the questions and answers that were
                previously on this page?
              </Question>
              <Answer>
                Questions and answers that directly relate to the operation of
                our tools are now contained in a new App FAQ. From the therapist
                dashboard, you will find it in the left menu.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                What video conferencing providers does ActiveEMDR support?
              </Question>
              <Answer>
                Doxy.me (free, professional, and clinic) is embedded right into
                the tools. Zoom, Google Meet, Any Meeting, VSee Clinic, and
                Simple Practice have connectors so that we can manage and send
                you and your client to the right place.
              </Answer>
              <Answer>
                There is also a Generic Connector that should work with any
                video conferencing provider that offers a web browser based
                video conferencing option.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                Do I have to start a session and wait for my client?
              </Question>
              <Answer>
                No. As long as you and your client are using the same "Session
                Password", the client can join first and wait for you, or vice
                versa.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                Will this work if my client is in my office with me?
              </Question>
              <Answer>
                Yes! If your client is with you in-person and using the same
                computer, simply start a session as a therapist.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                If I'm resizing my windows or using a phone, won't it affect
                what my client is seeing?
              </Question>
              <Answer>
                You are not sharing your screen with your client. They are
                seeing their own unique instance of the tool. The commands you
                use are sent to the client and run on their device. You can
                resize windows or move them around without having an affect on
                your client.
              </Answer>
              <Answer>
                The only caveat is that minimizing the BLS window or expanding
                another browser window or application that covers the BLS window
                may affect play back on your side. It will not affect the
                playback for your client. The technical reason for this is that
                most browsers pause a website when it is minimized or completely
                hidden from view. There is nothing we can do to get around this.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                If I am not sharing my screen, how do I know exactly what the
                client is seeing?
              </Question>
              <Answer>
                To get a feel, we suggest you be the client and therapist at the
                same time by using two different browser windows or even better,
                different computers.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                Will any browser work?
              </Question>
              <Answer>
                Most modern browsers will work. It has been tested on Chrome,
                Safari, Firefox, and Microsoft's Edge Browser. It will not work
                properly on Opera or Internet Explorer.
              </Answer>
              <Answer>
                Javascript must be enabled and browser cookies must be allowed
                in order for our tools to work.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                Can my client control the tool on their end?
              </Question>
              <Answer>No. Your client won't even see the controls. </Answer>

              <Question style={{ color: color_subHeading }}>
                I don't get it!
              </Question>

              <Answer>
                Check out our{" "}
                <a
                  href="https://www.youtube.com/channel/UCcbAmJDgC_GxU0Pombs1iNA"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    color: color_paragraphHighlite,
                    fontWeight: "800",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  YouTube Channel
                </a>{" "}
                to see if the videos help. If not, please contact us and we can
                have an email or phone conversation.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                How reliable is this tool and access to the tool?
              </Question>
              <Answer>
                It is currently being used by hundreds of therapists and their
                clients every day. We urge you to give it a try and if it isn't
                exactly what you are looking for, let us know so that we can
                make it so!
              </Answer>
              <Answer>
                The website and app themselves are deployed on multiple servers
                throughout the world and if one goes down, the others are ready
                to take the load. The infrastructure can easily support
                thousands of clients and therapists at one time.
              </Answer>
              <Question style={{ color: color_subHeading }}>
                I found an issue or problem. What do I do?
              </Question>
              <Answer>
                Please contact me and let me know as many details as possible.
                There are quite a number of therapists using this with their
                clients, I promise it does work for most. With that said, there
                may be edge cases that need attention. I'm happy to work with
                everyone to get it going.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                Are you HIPAA compliant?
              </Question>
              <Answer>
                We never send, receive, or even see Protected Health
                Information. There is never a risk of PHI accidentally being
                transmitted through our tool or website. Nothing is saved and we
                never ask for any personal information. Your video communication
                is handled by a third party. We urge you to visit their website
                to understand their security standards.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                How secure are the ActiveEMDR Sessions?
              </Question>
              <Answer>
                The more complicated you make your Session Password, the more
                unlikely that someone else will guess it. Since we do send links
                to your video sessions, we strongly suggest that you make sure
                your video conference settings only allow one participant at at
                time! The worst case scenario is that someone intercepts a video
                link and joins the video conferencing session. For that reason,
                it is in your best interest to double check security on your
                providers website by either password protecting your video
                sessions or simply confirming each conference attendee as they
                try to join.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                Are you going to charge money?
              </Question>
              <Answer>
                There are no plans to charge for these tools. Right now, the
                costs are within reason and we will continue to cover those.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                What makes you an EMDR expert?
              </Question>
              <Answer>
                Maybe it is time for an introduction. My name is Rick May, I'm a
                designer/developer living in the beautiful Pacific Northwest
                with my wife and two children. As far as my EMDR expertise. Well
                honestly, if it weren't for my wife, I may not have known that
                it even existed.
              </Answer>
              <Answer>
                My wife, Dr. Tara May is an EMDRIA Certified Therapist and
                Approved Consultant. She has influenced the tools direction
                since it began its life as a Windows application in 2013. She
                has been using it successfully with her in-office clients ever
                since. Tara also serves as my idea checker. She makes sure
                everything is in line with EMDR standards, protocols, and best
                practices.
              </Answer>
              <Answer>
                When the COVID-19 health crisis came along, and other available
                options not meeting needs, we decided it was time for that
                original application to evolve. Now a web based app, it can be
                used equally well in-person or in a telehealth environment.
                Given the situation, why not make it available to anyone and
                everyone who needs it?
              </Answer>
            </div>
          </motion.div>
        </Content>
        {!props.skipHeader && <Footer />}
      </Screen>
    </>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Question = styled.h2`
  font-family: montserrat;
  margin: 6vh 0 1vh 0;
  line-height: 1.4em;
  font-size: 1.1em;
  font-weight: 800;
`
const Answer = styled.h3`
  font-family: montserrat;
  margin: 0 0 2vh 1vw;
  font-weight: 600;
  font-size: 0.8em;
  line-height: 1.6em;
`
export default FAQ
